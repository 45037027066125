import { io } from 'socket.io-client';
import appConfig from "configs/app.config";

let socket;

const registerSocket = (userId) => {
//   socket = io(`${appConfig.apiBaseUrl}?userId=${userId}`); // Replace with your server URL
if (socket) return;

    socket = io(`${appConfig.apiBaseUrl}`, {
    reconnectionDelayMax: 10000,
    // auth: {
    //   token: "123"
    // },
    query: {
      "userId": userId
    }
  });
  // Add event listeners and emit events as needed
  socket.on('socketConnected', (data) => {
    console.log('Received message:', data);
  });

  const handleZip = (data) => {
    console.log('Received zip:', data);

    const fileUrls = data?.map((download) => {
      return { link: download?.url, name: download?.name };
    });

    // Delay the download by 20 seconds
    setTimeout(async() => {
      for (const url of fileUrls) {
        try {
          const response = await fetch(url.link, {
            method: "GET",
          }).then((response) => response.blob())
          .then((blob) => {
            // Create blob link to download
            const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", `${url.name}`);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
          });

          // if (!response.ok) {
          //   throw new Error(`Failed to fetch file: ${response.status} - ${response.statusText}`);
          // }
        } catch (error) {
          console.error('Error downloading file:', error);
        }
      }
    }, 10000);
  };

  socket.on('zip', handleZip);

  // Return the socket instance
  return socket;
};
const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
};

export { registerSocket, socket ,disconnectSocket};

