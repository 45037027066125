export const TOKEN_TYPE = "Bearer ";
export const REQUEST_HEADER_AUTH_KEY = "Authorization";

export const LIST_DATA_TYPE = {
  AMENITIES: 1,
  ADD_ONS: 2,
  ACTIVITES: 3,
  TAXATION: 4,
  TAGS: 5,
  CONTACT: 6,
  NEWSLETTER: 7,
  BLOG_CATEGORY: 8,
  BLOG_SUBCATEGORY: 9,
  BLOGS: 10,
};

export const APIS = {
  DASHBOARD: "/admin/dashboard",
  ADD_EDIT_TAGS: "admin/addEditTags",
  GET_ACTIVITIES: "/admin/activities",
  ADD_EDIT_ACTIVITY: "/admin/addEditActivities",
  GET_PROPERTIES: "/admin/properties",
  ADD_EDIT_PROPERTY: "/admin/addEditProperty",
  GET_PACKAGES: "/admin/packages",
  ADD_EDIT_PACKAGES: "/admin/addEditPackages",
  GET_BOOKINGS: "/admin/bookings",
  FETCH_BOOKING: "/admin/fetchbookings",
  GET_CUSTOM_BOOKINGS: "/admin/custombookings",
  GET_CUSTOMERS: "/admin/users",
  ADD_EDIT_CUSTOMERS: "/admin/addEditUser",
  GET_STAFF: "/admin/listAdmins",
  ADD_EDIT_STAFF: "/admin/addEditAdmins",
  GET_DISCOUNTS: "/admin/coupons",
  ADD_EDIT_CATEGORY: "admin/addEditCategory",
  ADD_EDIT_SUBCATEGORY: "admin/addEditSubCategory",
  ADD_EDIT_BLOG: "admin/addEditBlog",
  ADD_EDIT_DISCOUNTS: "admin/addEditCoupons",
  ADD_EDIT_BOOKING: "/admin/addEditBooking",
  ADD_EDIT_CUSTOM_BOOKING: "/admin/addEditCustomBooking",
  GET_BLOGS: "admin/blogs",
  LIST_DATA: "/admin/listData",
  ADD_EDIT_AMENITIES: "/admin/addEditAmenities",
  ADD_EDIT_ADDONS: "/admin/addEditAddons",
  ADD_EDIT_TAXATION: "admin/addEditTaxes",

  UPLOAD_IMAGE: "/api/uploadImage",
  REMOVE_IMAGE: "/admin/deleteImage",
  DELETE_DATA: "/admin/deleteData",

  STATE_LIST: "/api/getCountryStateCity",
  ROOM_AVAILABILITY: "/api/checkRoomAvailability",
  PROPERTY_AVAILABILITY: "api/checkPackageAvailability",
  ACTIVITY_AVAILABILITY: "api/checkActivityAvailability",
  SEND_NOTE: "admin/addOrderNotes",

  GET_ALL_ROOMS_OPTIONS: "/api/get-all-room-options",

  GENERATE_PAYMENT_LINK: "/admin/generatePaymentLink",
  GENERATE_INVOICE: "admin/generateInvoice",
  GENERATE_CUSTOM_INVOICE: "admin/generateCustomInvoice",
  GENERATE_BULK_INVOICE: "admin/bulkdownload",
  CREATE_INVOICE: "/admin/create-invoice",
  REGENERATE_INVOICE: "/admin/regenerate-invoice",
  //CREATE_IND_INVOICE: "/admin/recreate-invoice-with-existing-data",
  SEND_EMAIL_WITH_INVOICES: "/admin/send-email-with-invoices",
  SEND_MANUAL_CONFIRMATION_EMAIL: "/admin/send-manual-confirmation-email",

  CHANGE_PASSWORD: "admin/changePassword",
  UPDATE_PASSWORD: "api/updatePassword",
  APPLY_DISCOUNT: "admin/bookingDiscount",
  MANUAL_PAYMENT: "admin/addManualPayment",
  DELETE_MANUAL_PAYMENT: "admin/deleteManualPayment",

  INVOICE_LIST: "/admin/invoicelist",

  GET_INVOICE_TAX_DATA: "/admin/get-tax-data",

  ORDER_INVOICES: "/admin/bookings/invoices",

  STAY_INVOICES: "/admin/invoice/stay-invoices",
  ADV_INVOICES: "/admin/invoice/adv-invoices",
  CUSTOM_INVOICES: "/admin/invoices/custom-invoices",
};
